import { Box, Button, Container, IconButton, Typography } from "@mui/material"
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { createOrganization, deleteOrganization, getOrganizations, TOrganization } from "../utils/network/organizations";
import { useMutation, useQuery } from "@tanstack/react-query";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Users = () => {

const columns = [
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
    type: 'dateTime',
    valueGetter: (params: any) => {
      return new Date(params.row.created_at);
    }
  },
  {
    field: 'delete',
    type: 'actions',
    width: 60,
    getActions: (params: GridRenderCellParams<TOrganization, number>) => {
      return [
        <IconButton color="error" onClick={() => {
          handleDeleteOrg(params.row.workos_organization_id);
        }}>
          <DeleteOutlineIcon />
        </IconButton>
      ]
    },
  },
  {
    field: 'workos_organization_id',
    headerName: 'Organization ID',
    type: 'string',
    width: 320,
  },
  {
    field: 'name',
    headerName: 'Organization Name',
    width: 300,
    type: 'string',
  },
  {
    field: 'templates_count',
    headerName: 'SKUs',
    width: 80,
    type: 'number',
  },
  {
    field: 'tags_count',
    headerName: 'Tags',
    width: 120,
    type: 'number',
  },
  {
    field: 'tag_verifications_count',
    headerName: 'Tag Verifications',
    width: 150,
    type: 'number',
  },
  {
    field: 'external_tag_verifications_count',
    headerName: 'API Tag Verifications',
    width: 150,
    type: 'number',
  },
]

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['Users'],
    queryFn: async () => {
      return await getOrganizations()
    }
  })

  const { isPending: isCreatePending, mutate: create } = useMutation({
    mutationFn: async (name: string) => {
      return await createOrganization(name);
    }
  })

  const { mutate: deleteOrg } = useMutation({
    mutationFn: async (workos_organization_id: string) => {
      return await deleteOrganization(workos_organization_id);
    }
  })

  const handleDeleteOrg = async (workos_org: string) => {

    if (!window.confirm('Are you sure you want to delete this org and all its associated SKUs and tags? This is irreversible.')) {
      return;
    }

    if (!window.confirm('Are you really sure? This is action is irreversible and deletes all SKUs and tags.')) {
      return;
    }

    await deleteOrg(workos_org, {
      onSettled: () => {
        refetch();
      }
    });
  }

  const handleCreateOrganization = async () => {
    let name = prompt("Please enter a name for the new organization:", "New Organization");
    if (!name) {
      alert("Organization name is required");
      return
    }
    await create(name, {
      onSettled: () => {
        refetch();
      }
    });
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Organizations</Typography>
      <Button variant="contained" sx={{ my: 2 }}
        onClick={handleCreateOrganization}
        startIcon={<AddIcon />}
        disabled={isCreatePending}
      >
        New Organization
      </Button>
      <Box sx={{ height: 20, width: '100%' }} />
      <DataGrid
        autoHeight
        getRowId={(row) => row.workos_organization_id}
        rows={data || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default Users
