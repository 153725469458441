import { LGT_SERVER_URL } from "./baseUrl";
import { NetworkRequestError } from "./error";

export const downloadCsvExport = async (template_id: number) => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/owners/export_csv/${template_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Disposition': 'attachment',
      },
    });

    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }

    const blob = await response.blob();

    // hack to download the file with fetch with an auth token in the header
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `sku_${template_id}_owners_by_tag_id_${new Date().toISOString()}.csv`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();  //afterwards we remove the element again

  } catch (e) {
    throw e;
  }
};
