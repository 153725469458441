import { Alert, AlertTitle, Button, CircularProgress, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { deleteTemplate, getTemplates, transferTemplate, TTemplate } from "../../utils/network/templates";
import { useState } from "react";
import { useNavigate,  } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { transferTags } from "../../utils/network/tags";

const ManageTags = ({ template }: { template: TTemplate }) => {
  const [isTransferringOrDeleting, setIsTransferringOrDeleting] = useState(false);
  const [receivingOrgId, setReceivingOrgId] = useState<string>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [startingSerial, setStartingSerial] = useState<string>();
  const [endingSerial, setEndingSerial] = useState<string>();
  const [transferStatus, setTransferStatus] = useState<string>('');
  const navigate = useNavigate();

  const { data: skus } = useQuery({
    queryKey: ['SKUs'],
    queryFn: async () => {
      const resp = await getTemplates()
      return resp;
    },
  })

  const handleTransferTags = async () => {

    if (!selectedTemplate) {
      return;
    }

    setTransferStatus('Transferring…');

    if (Number(startingSerial) > Number(endingSerial)) {
      setTransferStatus('Starting serial number cannot be greater than the ending serial number.');
    }

    if (!window.confirm(`Are you sure you want to transfer tags ${startingSerial}-${endingSerial} from SKU ${template.id} to SKU ${selectedTemplate}?`)) {
      return;
    }

    setIsTransferringOrDeleting(true);

    try {
      const tags = await transferTags(template.id, Number(selectedTemplate), Number(startingSerial), Number(endingSerial));
      setTransferStatus(`${tags.transferred.length} tags successfully transferred - [${tags.transferred.sort().join(', ')}]`);
      setStartingSerial('');
      setEndingSerial('');
      setSelectedTemplate('');
    } catch (e) {
      setTransferStatus("Something went wrong: " + JSON.stringify(e));
    }

    setIsTransferringOrDeleting(false);
  }

  const handleDeleteSKU = async () => {
    if (!window.confirm(`Are you sure you want to delete SKU ${template.id}?\nThis action is irreversible!`)) {
      return;
    }

    if (!window.confirm(`All ${template.tags_count} tags within this SKU will be deleted.\nDo you want to proceed?`)) {
      return;
    }

    setIsTransferringOrDeleting(true);

    try {
      await deleteTemplate(template.id);
      navigate('/skus');
    } catch (e) {
      console.error(e);
    }

    setIsTransferringOrDeleting(false);
  }

  const handleTransferSKU = async () => {
    if (!receivingOrgId) {
      return;
    }

    if (!window.confirm(`Are you sure you want to transfer SKU ${template.id} to Organization ID ${receivingOrgId}?`)) {
      return;
    }

    setIsTransferringOrDeleting(true);

    try {
      await transferTemplate(template.id, receivingOrgId);
      navigate('/skus');
    } catch (e) {
      console.error(e);
    }

    setIsTransferringOrDeleting(false)
  }

  return (
    <Stack spacing={2} marginY={4}>
      <Typography variant="h4">4. Manage Tags</Typography>
      <Alert severity="warning">
        <AlertTitle variant="subtitle1">Transfer Tags</AlertTitle>
        <Typography variant="body2">Transfer a range of tags by serial number to a different SKU.
          The serial number of the moved tags will be reordered to match the new SKU's sequence.<br /><br/>
          i.e. If you transfer tags 1-10 to another SKU that already has tags 1-50, the transferred tags will be renumbered to 51-60.
        </Typography>
        <br />
        <TextField
          select
          label="Select SKU to Transfer to"
          size="small"
          onChange={e => setSelectedTemplate(e.target.value)}
          fullWidth
          required
          disabled={false}
        >
          <MenuItem key='none' value={''}>
            Select SKU
          </MenuItem>
          {skus?.map((sku) => (
            <MenuItem key={sku.id} value={sku.id} disabled={sku.id === template.id}>
              {sku.id} - {sku.name}
            </MenuItem>
          ))}
        </TextField>
        <br /><br />
        <Stack spacing={2} direction="row">
          <TextField type="number"
            label="Starting Tag Serial Number"
            required
            size="small"
            autoComplete="off"
            onChange={e => setStartingSerial(e.target.value)}
            value={startingSerial}
            disabled={isTransferringOrDeleting}
            helperText="This is the first tag in the range of tags to transfer, inclusive of this tag."
          />
          {<TextField type="number"
            label="Ending Tag Serial Number"
            required
            size="small"
            onChange={e => setEndingSerial(e.target.value)}
            value={endingSerial}
            autoComplete="off"
            disabled={isTransferringOrDeleting}
            helperText="This is the last tag in the range of tags to transfer, inclusive of this tag."
          />}
        </Stack>
        {transferStatus && <><br />{transferStatus}<br /></>}
        <br />
        <Button variant="outlined" color="warning"
          disabled={isTransferringOrDeleting || !selectedTemplate || !startingSerial || !endingSerial}
          onClick={handleTransferTags}
          size="small"
          startIcon={isTransferringOrDeleting && <CircularProgress size={16} />}
        >
          Transfer Tags{startingSerial && endingSerial ? ` ${startingSerial}-${endingSerial}` : ''}
        </Button>
      </Alert>
      <Alert severity="warning">
        <AlertTitle variant="subtitle1">Transfer SKU</AlertTitle>
        <Typography variant="body2">Transfer this SKU and all associated tags to another organization.</Typography>
        <br />
        <TextField
          size="small"
          type="text"
          value={receivingOrgId}
          onChange={e => setReceivingOrgId(e.target.value)}
          label="Receiving Organization ID"
          required
          autoComplete="off"
          placeholder="org_01J8GV7RK5YAJZPJD7Y176MCZ2"
          fullWidth
          helperText="The organization ID can be found in the Organization page in the User dropdown when logged in."
          disabled={isTransferringOrDeleting}
        />
        <br /><br />
        <Button variant="outlined" size="small" color="warning" disabled={isTransferringOrDeleting || !receivingOrgId} onClick={handleTransferSKU}
           startIcon={isTransferringOrDeleting && <CircularProgress size={16} />}
        >
          Transfer SKU
        </Button>
      </Alert>
      <Alert severity="error">
        <AlertTitle variant="subtitle1">Delete SKU {template.id}</AlertTitle>
        <Typography variant="body2">All {template.tags_count} tags associated with this SKU will be deleted
          and will not be verifiable by Legitimate or used in the future. <br />
          These tags cannot be reassigned, reprogrammed, or erased after being deleted. This action is irreversible.
        </Typography>
        <br />
        <Button size="small" variant="outlined" color="error" disabled={isTransferringOrDeleting} onClick={handleDeleteSKU}
           startIcon={isTransferringOrDeleting && <CircularProgress size={16} />}
        >
          Delete SKU
        </Button>
      </Alert>
    </Stack>
  );
}

export default ManageTags;
