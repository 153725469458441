import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
} from 'react-router-dom';
import { ThemeProvider} from "@mui/material";
import { LightTheme } from './utils/theme/MuiTheme'
import NavigationBar from './NavigationBar'
import Skus from './Skus'
import Users from './Organizations'
import ViewSku from './Skus/View';
import CreateSku from './Skus/Create';
import Tags from './Tags';

import "./App.css"
import Profile from './Account/Profile';
import { QueryClient, QueryClientProvider, useMutation, useQuery } from '@tanstack/react-query';
import TagVerifications from './TagViews';
import GasCalculator from './Gas';
import SplashPage from './SplashPage';
import { AuthContext } from './utils/auth/context';
import { getSession, refreshSession } from './utils/network/auth';
import Callback from './Callback';
import Organization from './Account/Organization';
import OwnersIndex from './Owners';

const PrivateRoutes = () => {
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ['session'],
    queryFn: getSession,
    retry: false,
  })

  const { mutate } = useMutation({
    mutationKey: ['session'],
    mutationFn: refreshSession,
    onError: () => {
      localStorage.removeItem('token');
      navigate('/');
    },
    onSuccess: (data,) => {
      localStorage.setItem('token', data.token);
    }
  })

  const { isError } = query;

  if (isError) {
    mutate(undefined);
  }

  return (
    <AuthContext.Provider value={query}>
      <NavigationBar />
    </AuthContext.Provider>
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<PrivateRoutes/>}>
        <Route path="/skus" element={<Skus />} />
        <Route path="/skus/new" element={<CreateSku />} />
        <Route path="/skus/:skuId" element={<ViewSku />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/views" element={<TagVerifications />} />
        <Route path="/organizations" element={<Users />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/organization" element={<Organization />} />
        <Route path="/owners" element={<OwnersIndex />} />
        <Route path="/gas" element={<GasCalculator />} />
      </Route>
      <Route path="/" element={<SplashPage />} />
      <Route path="/auth/callback" element={<Callback />} />
    </Route>
  )
);

export default function App() {
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={LightTheme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
