import React, { useContext } from 'react';
import { AppBar, Box, Toolbar, Typography, Menu, Container, Button, MenuItem, Link, Avatar } from '@mui/material';
import { ReactComponent as LogoSymbol } from './assets/LogoSymbol.svg'
import { Outlet, useLocation } from 'react-router-dom';
import { API_STAGE, ENVIRONMENT_LABELS } from './utils/network/baseUrl';
import { isLegitimateOrg } from './utils/network/organizations';
import { ROLE, ROLE_LABELS } from './utils/auth/roles';
import { AuthContext } from './utils/auth/context';
import { LOGOUT_URL } from './utils/network/auth';

const pages = [{
  title: 'SKUs',
  href: '/skus'
}, {
  title: 'Tags',
  href: '/tags'
}, {
  title: 'Verifications',
  href: '/views'
}, {
  title: 'Owners',
  href: '/owners'
}, {
  title: 'Gas',
  href: '/gas'
}];

const admin = [
{
  title: 'Organizations',
  href: '/organizations'
}];

function ResponsiveAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);

  const { pathname } = useLocation();
  const { data: session } = useContext(AuthContext);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem('token');
    window.location.href = LOGOUT_URL + session?.session_id;
  }


  const user = session?.user;
  const organizationId = session?.organization_id;
  const role = session?.role;
  const impersonator = session?.impersonator !== 'null' && session?.impersonator;

  return (
    <>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link href="/" sx={{ lineHeight: 0}} key="logo">
              <LogoSymbol style={{ fill: 'currentColor', width: '32px', marginRight: '24px' }} />
            </Link>

            {user && <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {pages.map((page) => (
                <Link href={page.href} key={page.href}>
                  <Button
                    key={page.title}
                    sx={{ my: 2, color: 'white', display: 'block',
                      textDecoration: pathname.indexOf(page.href) ===0 ? 'underline' : 'none',
                      borderRadius: 0,
                    }}
                    href={page.href}
                  >
                    {page.title}
                  </Button>
                </Link>
              ))}
              {isLegitimateOrg(organizationId!) && role === ROLE.ADMIN &&
              <>
                <Link href="#" key="admin">
                  <Button
                    sx={{
                      my: 2, color: 'white', display: 'block',
                      borderRadius: 0,
                    }}
                    onClick={handleOpenAdminMenu}
                  >
                    Admin
                  </Button>
                </Link>
                <Menu
                  key="adminMenu"
                  sx={{ mt: '45px' }}
                  id="menu-admin"
                  anchorEl={anchorElAdmin}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={Boolean(anchorElAdmin)}
                  onClose={handleCloseAdminMenu}
                >

                  {admin.map((page) => <Link href={page.href} key={page.href}>
                    <MenuItem key={page.title + page.href}>
                      <Typography>{page.title}</Typography>
                    </MenuItem>
                  </Link>)}
                </Menu>
              </>}
            </Box>}

            {user && <Box sx={{ flexGrow: 0 }}>
              <Button key="userButton" onClick={handleOpenUserMenu} sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none',
                padding: 0
              }}
              >
                <Avatar src={user?.profile_picture_url || ''}>
                  {user?.first_name?.charAt(0).toUpperCase() || user?.email.charAt(0).toUpperCase()}{user?.last_name?.charAt(0).toUpperCase()}
                </Avatar>
              </Button>
              <Menu
                key="userMenu"
                sx={{ mt: '45px' }}
                id="menu-user"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >

                <Link href="/organization" sx={{ textDecoration: 'none' }}>
                  <MenuItem key="organization">
                    <Typography>Organization</Typography>
                  </MenuItem>
                </Link>
                <Link href="/profile" sx={{ textDecoration: 'none' }}>
                  <MenuItem key="profile">
                    <Typography>Profile</Typography>
                  </MenuItem>
                </Link>
                <MenuItem key="baseUrl" disabled>
                  <Typography variant='body2'>Env: {ENVIRONMENT_LABELS[API_STAGE]}</Typography>
                </MenuItem>
                <MenuItem key="role" disabled>
                  <Typography variant='body2'>Role: {ROLE_LABELS[role! as ROLE]}</Typography>
                </MenuItem>
                {impersonator && <MenuItem key="impersonator" disabled>
                  <Typography variant='body2'>Impersonator: {impersonator}</Typography>
                </MenuItem>}
                <MenuItem key="logout" onClick={handleLogOut}>
                  <Typography>Log Out</Typography>
                </MenuItem>
              </Menu>
            </Box>}
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
}
export default ResponsiveAppBar;
