import { Alert, Box, Button, Container, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react"
import { useQuery } from "@tanstack/react-query";
import { getTemplates } from "../utils/network/templates";
import { downloadCsvExport } from "../utils/network/owners";
const OwnersIndex = () => {

  const [selectedSku, setSelectedSku] = useState<string>('');

  const { data: skus, isLoading: isSkusLoading } = useQuery({
    queryKey: ['SKUs'],
    queryFn: async () => {
      return await getTemplates();
    },
  })

  const handleTemplateSelect = (templateId: string) => {
    setSelectedSku(templateId);
  }

  const handleDownloadExport = () => {
    downloadCsvExport(Number(selectedSku));
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Owners</Typography>
      <Box sx={{ height: 20, width: '100%' }} />
      <Select
        displayEmpty
        onChange={e => handleTemplateSelect(e.target.value)}
        sx={{ width: 400 }}
        defaultValue={''}
        disabled={isSkusLoading}
      >
        <MenuItem disabled key='none' value={''}>
          Select SKU
        </MenuItem>
        {skus?.map((sku) => (
          <MenuItem key={sku.id} value={sku.id}>
            {sku.id} - {sku.name}
          </MenuItem>
        ))}
      </Select>
      <Box my={4}>
      The CSV export will contain the following information:
        <ul>
          <li>SKU ID</li>
          <li>Tag ID</li>
          <li>Serial Number</li>
          <li>Owner ID</li>
          <li>Owner Email</li>
          <li>Marketing Consent Status</li>
        </ul>

        Owners who did not consent for marketing will not have their emails included in the export.

      </Box>
      <Alert severity="warning">
        By downloading a CSV export of the ownership information for your Legitimate Tags,
        you agree to the terms and conditions set forth by Legitimate, Inc. and agree to not
        hold Legitimate responsible for any misuse of the data contained in the export.
      </Alert>
      <Button
        variant="contained"
        disabled={!selectedSku}
        sx={{ marginTop: 2 }}
        onClick={handleDownloadExport}
      >
        Download CSV Export
      </Button>
    </Container>
  )
}

export default OwnersIndex;
